import * as React from 'react'
import { RecordContextProvider, required, SelectInput, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import { useTotalContext } from 'src/context/total_state'
import { useEffect } from 'react'
import axios from 'axios'
import { useCommonStore } from 'src/store/common.store'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
}

let cancelReasonsLoading = false

export const PromoReserveCancelModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const totalContext = useTotalContext()
  //
  const { cancelReasons, setCancelReasons } = useCommonStore()

  useEffect(() => {
    if (!cancelReasonsLoading && !cancelReasons) {
      cancelReasonsLoading = true
      //
      getCancelReasons()
    }
  }, [cancelReasonsLoading])

  const getCancelReasons = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL || ''}/admin/api/promo-reserve/cancel-reason/list`)

      if (res.status === 200) {
        setCancelReasons(res.data)
      }
    } catch (err) {
      cancelReasonsLoading = false
      //
      throw err
    }
  }

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.update('promo-reserve/setCancelled', {
      id: record.id,
      data,
      previousData: record,
    })

    if (props.onClose) {
      props.onClose()
    }
    refresh()
    totalContext.fetchPromoReserve()
    //
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{}}
        fullWidth={true}
        maxWidth={'xs'}
        resource={'cancelReason'}
        title={'Отменить бронь'}
        onClose={props.onClose}
        saveButtonLabel={'Подтвердить'}
        cancelButtonLabel={'Назад'}
        save={handleSubmit}
      >
        <SelectInput
          name={'cancelReason'}
          source={'cancelReason'}
          label={'Причина отмены'}
          choices={cancelReasons || []}
          disabled={!cancelReasons}
          validate={required()}
        />
      </ModalForm>
    </RecordContextProvider>
  )
}
