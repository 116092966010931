export const OrderFieldsMap = {
  userId: 'Клиент',
  partnerId: 'Партнер',
  brandId: 'Бренд',
  unitId: 'Заведение',
  areaId: 'Зона доставки',
  number: 'Номер',
  partnerNumber: 'Номер у партнера',
  address: 'Адрес',
  location: 'Координаты',
  payload: '',
  status: 'Статус',
  prevStatus: 'Предыдущий статус',
  paymentMethod: 'Способ оплаты',
  deliveryMethod: 'Способ доставки',
  totalDiscount: 'Сумма скидки',
  totalDelivery: 'Сумма доставки',
  total: 'Сумма',
  mark: 'Оценка',
  platform: 'Платформа',
  auctionId: 'Аукцион',
  personsCount: 'Кол-во персон',
  isPreOrder: 'Предзаказ',
  paymentStatus: 'Статус оплаты',
  preOrderAt: 'Время предзаказа',
  exceptedDeliveryAt: 'Планируемое время доставки',
  exceptedDeliveryTime: 'Планируемое время доставки',
  deliveredAt: 'Время доставки',
  lateTime: 'Опоздание',
  ulId: '',
  email: 'Email',
  phone: 'Телефон',
  orderId: 'ID заказа',
  orderNumber: 'Номер заказа',
  paidAt: 'Время оплаты',
  confirmedAt: 'Время принятия',
  state: 'Статус карточки',
  stateDetails: '',
  moneyChange: 'Сдача с',
} as const
