import * as React from 'react'
import {
  SimpleForm,
  required,
  SelectInput,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanInput,
} from 'react-admin'
import {
  CancelReasonEntityType,
  CancelReasonEntityTypeList,
  CancelReasonUserType,
  CancelReasonUserTypeList,
  CancelReasonUserTypeListForCreationAndEdit,
} from 'src/types/enum/CancelReason'
import { useRecordContext } from 'ra-core'
import modalFormStyles from 'src/components/ModalForm/index.module.scss'

const Form = () => {
  const record = useRecordContext()

  return (
    <>
      <TextInput
        source={'labelPartner'}
        label={'Текст для партнеров'}
        fullWidth={true}
        variant={'outlined'}
        disabled={record && !record.canBeDeleted}
        validate={required()}
      />
      <TextInput
        source={'labelClient'}
        label={'Текст для клиентов'}
        fullWidth={true}
        variant={'outlined'}
        disabled={record && !record.canBeDeleted}
        validate={required()}
      />
      <SelectInput
        source={'entity'}
        label="Назначение причины"
        defaultValue={CancelReasonEntityType.PromoReserve}
        choices={CancelReasonEntityTypeList}
        fullWidth={true}
        disabled={record && !record.canBeDeleted}
        validate={required()}
      />
      <SelectInput
        source={'userType'}
        label="Предназначен"
        defaultValue={CancelReasonUserType.ForEveryone}
        choices={record && !record.canBeDeleted ? CancelReasonUserTypeList : CancelReasonUserTypeListForCreationAndEdit}
        fullWidth={true}
        disabled={record && !record.canBeDeleted}
        validate={required()}
      />
      <BooleanInput source={'active'} label={'Видимость'} disabled={record && !record.canBeDeleted} />
    </>
  )
}

export const CancelReasonForm = (props: any) => {
  const record = useRecordContext()

  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      toolbar={
        <Toolbar className={modalFormStyles.toolbar}>
          <SaveButton />
          {record && record.canBeDeleted && <DeleteButton />}
        </Toolbar>
      }
    >
      <Form />
    </SimpleForm>
  )
}
